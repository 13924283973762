import React from "react";
import "./HeroSection.css";
import appPreview from "../assets/mockuuups-female-hand-holding-iphone-14-pro-mockup.png";
import appIcon from "../assets/appicon(1024*1024).png";

function HeroSection() {
  const handleDownloadClick = () => {
    // iOS 기기 감지
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    // Android 기기 감지
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      window.location.href = "https://apps.apple.com/app/urlmap/id6741013672"; // iOS 앱스토어 링크
    } else if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.urlmap.app"; // 플레이스토어 링크
    } else {
      alert("모바일에서 다운로드해주세요.");
    }
  };
  return (
    <div className="hero-container">
      <div className="hero-content">
        <img src={appIcon} alt="URLmap 앱 아이콘" className="app-icon" />
        <h1>
          카카오톡 나에게 보내기는
          <br /> 이제 그만
        </h1>
        <p>원하는 URL을 간편하게 저장하고 언제든지 꺼내보세요</p>
        <div className="hero-btns">
          <button className="download-btn" onClick={handleDownloadClick}>
            앱 다운로드
          </button>
          <button
            className="chrome-btn"
            onClick={() => window.location.href = "https://chromewebstore.google.com/detail/phlbckkakhcbfekndcoihecihfampaio?utm_source=item-share-cp"}
          >
            Chrome 확장프로그램 설치
          </button>
        </div>
      </div>
      <div className="hero-image">
        <img src={appPreview} alt="URLmap 앱 미리보기" />
      </div>
    </div>
  );
}

export default HeroSection;
