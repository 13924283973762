import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
      <h1>개인정보 처리방침</h1>

      <section>
        <h2>1. 개인정보의 처리 목적</h2>
        <p>
          URLmap('https://urlmaps.com' 이하 'URLmap')은 다음의 목적을 위하여
          개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지
          않습니다.
        </p>
        <ul>
          <li>서비스 제공을 위한 계정 생성 및 관리</li>
          <li>URL 저장 및 관리 기능 제공</li>
          <li>서비스 이용 기록 분석 및 통계</li>
        </ul>
      </section>

      <section>
        <h2>2. 개인정보의 처리 및 보유 기간</h2>
        <p>
          URLmap은 서비스 제공을 위해 필요한 최소한의 기간 동안 개인정보를
          보유하고 있습니다.
        </p>
        <ul>
          <li>회원 가입 정보: 회원 탈퇴 시까지</li>
          <li>서비스 이용 기록: 서비스 종료 시까지</li>
        </ul>
      </section>

      <section>
        <h2>3. 개인정보의 제3자 제공</h2>
        <p>
          URLmap은 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다.
          다만, 아래의 경우에는 예외로 합니다.
        </p>
        <ul>
          <li>이용자들이 사전에 동의한 경우</li>
          <li>
            법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
            따라 수사기관의 요구가 있는 경우
          </li>
        </ul>
      </section>

      <section>
        <h2>4. 개인정보의 파기</h2>
        <p>
          URLmap은 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당
          개인정보를 파기합니다.
        </p>
      </section>

      <section>
        <h2>5. 이용자의 권리와 의무</h2>
        <p>이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</p>
        <ul>
          <li>개인정보 열람 요구</li>
          <li>오류 등이 있을 경우 정정 요구</li>
          <li>삭제 요구</li>
          <li>처리정지 요구</li>
        </ul>
      </section>

      <section>
        <h2>6. 개인정보 보호책임자</h2>
        <p>
          URLmap은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
          처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기 위하여 아래와
          같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <div>
          <p>▶ 개인정보 보호책임자</p>
          <p>이메일: urlmaps43@gmail.com</p>
        </div>
      </section>

      <section>
        <h2>7. 개인정보 처리방침 변경</h2>
        <p>
          이 개인정보처리방침은 2024년 3월 1일부터 적용됩니다. 법령 및 방침에
          따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일
          전부터 공지사항을 통하여 고지할 것입니다.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
