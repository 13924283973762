// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDflzkut8OvmEdxaChYkmj-gusTFkJ2Hs0",
  authDomain: "urlmap-46bd1.firebaseapp.com",
  projectId: "urlmap-46bd1",
  storageBucket: "urlmap-46bd1.firebasestorage.app",
  messagingSenderId: "630382721893",
  appId: "1:630382721893:web:6b8360be3a2326cd5cbf8c",
  measurementId: "G-BYJHHWG1B7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
