import React, { useEffect } from "react";
import "./Features.css";
import mockupImage from "../assets/mockuuups-transparent-iphone-16-pro-mockup-titanium-black.png";
import mainImage from "../assets/iphone_main.png";
import extensionImage from "../assets/확장프로그램.png";
import extension2Image from "../assets/확장프로그램2.png";

function Features() {
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("feature-section-visible");
        }
      });
    }, observerOptions);

    document.querySelectorAll(".feature-section").forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  const features = [
    {
      title: (
        <>
          🔗 공유하기로 손쉬운
          <br />
          북마크 추가
        </>
      ),
      description:
        "공유 버튼을 통해 어떤 앱에서든 바로 북마크를 추가할 수 있습니다.",
      image: mockupImage,
      imagePosition: "right",
      imageClass: "mobile-mockup",
    },
    {
      title: (
        <>
          📁 체계적인 관리와
          <br />
          🎨 직관적인 인터페이스
        </>
      ),
      description: "폴더별로 북마크를 정리하고, 목록 뷰로 한눈에 확인하세요.",
      image: mainImage,
      imagePosition: "left",
      imageClass: "mobile-mockup",
    },
    {
      title: "🌐 다양한 플랫폼 지원",
      description: "안드로이드, iOS, 크롬확장프로그램에서 사용 가능합니다.",
      images: [extensionImage, extension2Image],
      imageClass: "extension-mockup",
      isColumn: true,
    },
  ];

  return (
    <section id="features" className="features">
      <div className="features-container">
        {features.map((feature, index) => (
          <div key={index} className="feature-section">
            <div
              className={`feature-content ${
                feature.isColumn
                  ? "column-layout"
                  : feature.imagePosition === "left"
                  ? "image-left"
                  : "image-right"
              }`}
            >
              <div className="feature-text">
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
              <div className={`feature-image ${feature.imageClass}`}>
                {feature.images ? (
                  <div className="extension-images">
                    {feature.images.map((img, i) => (
                      <img
                        key={i}
                        src={img}
                        alt={`${feature.title} ${i + 1}`}
                      />
                    ))}
                  </div>
                ) : (
                  <img src={feature.image} alt={feature.title} />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Features;
