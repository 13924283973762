import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>URLmap</h4>
          <p>나만의 URL을 쉽고 편리하게 관리하세요</p>
        </div>
        <div className="footer-section">
          <h4>다운로드</h4>
          <ul className="footer-list">
            <li>
              <a href="https://apps.apple.com/app/urlmap/id6741013672">iOS 앱</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.urlmap.app">
                Android 앱
              </a>
            </li>
            <li>
              <a href="#chrome" onClick={() => alert("아직 준비중입니다.")}>
                Chrome 확장프로그램
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>회사</h4>
          <ul className="footer-list">
            <li>
              <a href="#about">소개</a>
            </li>
            <li>
              <Link to="/privacy-policy">개인정보처리방침</Link>
            </li>
            <li>
              <a href="#terms">이용약관</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 URLmap. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
