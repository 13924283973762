import React from "react";
import "./Navbar.css";
import appIcon from "../assets/appicon(1024*1024).png";

function Navbar() {
  const handleDownloadClick = () => {
    // iOS 기기 감지
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    // Android 기기 감지
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      window.location.href = "https://apps.apple.com/app/urlmap/id6741013672"; // iOS 앱스토어 링크
    } else if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.urlmap.app"; // 플레이스토어 링크
    } else {
      alert("모바일에서 다운로드해주세요.");
    }
  };
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <img src={appIcon} alt="URLmap" className="navbar-icon" />
          URLmap
        </a>
        <ul className="nav-menu">
          <li className="nav-item">
            <button onClick={handleDownloadClick} className="nav-button">
              다운로드
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
